import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"
import { Button } from "app/core/components/Button"

type SharingDialogProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  adminSchoolName: string
}
const SharingDataDialog = ({ isOpen, onClose, onConfirm, adminSchoolName }: SharingDialogProps) => {
  return (
    <DialogOverlay isOpen={isOpen} onDismiss={onClose} className="modal--small">
      <DialogContent aria-label="Confirmation Dialog">
        <div className="p-2 space-y-4">
          <div className="">Do you consent to share data with {adminSchoolName}?</div>
          <div className="flex justify-end space-x-4">
            <Button
              type="button"
              onClick={onClose}
              className="bg-gray-200 text-black opacity-90 hover:opacity-100"
            >
              No
            </Button>
            <Button
              type="button"
              onClick={onConfirm}
              className="bg-green-500 opacity-90 hover:opacity-100"
            >
              Yes
            </Button>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}

export default SharingDataDialog

import SyncLoader from "react-spinners/SyncLoader"

export interface LoadingProps {
  size?: number
  className?: string
}

export default function Loading({ size = 8, className = "m-10" }: LoadingProps) {
  return (
    <div className={className}>
      <SyncLoader size={size} color={"rgb(77,155,168)"} />
    </div>
  )
}

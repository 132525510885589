import { ReactNode } from "react"
import { Head } from "blitz"

type LayoutProps = {
  title?: string
  children: ReactNode
}

export const BareLayout = ({ title, children }: LayoutProps) => (
  <>
    <Head>
      <title>{title || "Homeschool Boss"}</title>
    </Head>

    {children}
  </>
)

export default BareLayout

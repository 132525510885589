import { useRouter, BlitzPage, useSession, useRouterQuery, useMutation } from "blitz"
import BareLayout from "app/core/layouts/BareLayout"
import { LoginForm } from "../components/LoginForm"
import SharingDataDialog from "../components/SharingDataDialog"
import { Suspense, useEffect, useState } from "react"
import updateOrgAdminUser from "app/organizations/mutations/updateOrgAdminUser"
import Loading from "app/core/components/Loading"
import { User } from "@prisma/client"
import getUserByToken from "app/users/queries/getUserByToken"

type AdminUserType = {
  id: number
  organization: {
    schoolName: string | null
  }
}

const LoginPage: BlitzPage = () => {
  const router = useRouter()
  const query = useRouterQuery()
  const session = useSession()
  const [user, setUser] = useState<User | null>(null)
  const [adminUser, setAdminUser] = useState<AdminUserType | null>(null)
  const [showDialog, setShowDialog] = useState(false)
  const [updateOrgAdminUserMutation] = useMutation(updateOrgAdminUser)

  useEffect(() => {
    const token = typeof router.query.token === "string" ? router.query.token : ""

    if (session && session.userId) {
      if (!token) {
        router.push("/")
      } else {
        const fetchAdminUser = async () => {
          const user = await getUserByToken({ token })
          setAdminUser(user!)
        }
        fetchAdminUser()
      }
    }
  }, [router, session, query])

  useEffect(() => {
    if (adminUser) setShowDialog(true)
  }, [adminUser])

  const handleCloseDialog = () => {
    setShowDialog(false)
    router.push("/")
  }

  const handleConfirmDialog = async () => {
    await updateOrgAdminUserMutation({ id: user!.organizationId, orgAdminUserId: adminUser!.id })
    router.push("/")
  }

  return (
    <div>
      <LoginForm onSuccess={setUser} />
      <SharingDataDialog
        isOpen={showDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        adminSchoolName={adminUser ? adminUser.organization.schoolName! : ""}
      />
    </div>
  )
}

// LoginPage.redirectAuthenticatedTo = Routes.AUTHENTICATED_HOME_PAGE
LoginPage.getLayout = (page) => (
  <Suspense fallback={<Loading />}>
    <BareLayout title="Log In">{page}</BareLayout>
  </Suspense>
)

export default LoginPage
